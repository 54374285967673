<template>
  <b-card>
    <b-overlay :show="isLoadingAgreementData" rounded class="">
      <ValidationObserver ref="transactionRequestResponse" v-slot="{ invalid }">
        <b-row class="mb-1 pr-0" v-if="agreementData">
          <b-col>
            <h4>Aceptación de Transacciones del contrato {{agreementData.contractCode || ''}}</h4>
            <h6>{{agreementData.resortsname || ''}} | {{agreementData.housingnumber || ''}} | {{agreementData.membershipcode || ''}}</h6>
            <!-- <h6>Aceptación de transacciones {{ agreementData.ownerName }}</h6> -->
          </b-col>
          <b-col cols="2">
            <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'owners-transactions-aceptance'}">
              <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-badge class="text-center mr-1 mb-1" variant="primary" v-if="!!agreementData.code">
              {{agreementData.code}}
            </b-badge>
            <b-badge class="text-center mr-1 mb-1" variant="primary">
              {{agreementData.periodInitDate}} al {{agreementData.periodEndDate}}
            </b-badge>
            <b-badge class="text-center mr-1 mb-1" variant="primary">
              {{agreementData.periodId}}° periodo {{agreementData.periodName}} del año {{year(agreementData.periodInitDate)}}
            </b-badge>

            <b-button
              class="btn float-right"
              type="submit"
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              title="Consulte aquí el Saldo de Beneficios"
              :href="`${authURL}/FivesClub/benefitbalance/${agreementData.contract}/${year(agreementData.periodInitDate)}/`"
              target="_blank"
              rel="noopener noreferrer"
            ><feather-icon icon="FileTextIcon" /> Saldo de Beneficios
            </b-button>

            <b-alert show class="text-center p-1 mt-1" :variant="badgeStatus(agreementData)">Transacciones {{agreementData.statusName}}s</b-alert>
          </b-col>


          <b-col md="12">
            <div class="border rounded p-1 mb-1">
              <b-row align-v="center">
                <!-- <b-col cols="12">
                  <h6>Datos de </h6>
                </b-col> -->
                <b-col>
                  <label class="font-weight-bolder">{{ agreementData.statusName }} Por:</label><br>
                  {{ agreementData.ownerName }} <br>

                  <label class="font-weight-bolder">{{ agreementData.statusName }} el:</label><br>
                  {{ formatThisDate(agreementData.signDate) }}
                </b-col>
                <b-col md="10" v-if="!!agreementData.comments" class="border-left">
                  <span>
                    <label class="font-weight-bolder">Comentarios del Propietario:</label><br>
                    {{ agreementData.comments }}
                  </span>
                </b-col>
              </b-row>
            </div>
          </b-col>


          <b-col md="12" class="mb-2 mt-1" v-if="agreedTransactions && !agreementData.reviewedLogResolved">
            <ValidationProvider rules="required|min:50|max:500" name="respuesta">
              <b-form-group slot-scope="{ valid, errors }">
                <h6>Respuesta:</h6>
                <b-form-textarea
                  v-model="notes"
                  placeholder="Escribe la respuesta"
                  rows="3"
                  :state="(errors.length == 0 || valid) ? null : false"
                  />
                  <!-- :maxlength="500" -->
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12" v-else-if="!!agreementData.adminComments">
            <div class="border rounded p-1 mb-1">
              <b-row align-v="center">
                <b-col>
                  <label class="font-weight-bolder">Revisado Por:</label><br>
                  {{ agreementData.reviewByName }} <br>

                  <label class="font-weight-bolder">Revisado el:</label><br>
                  {{ formatThisDate(agreementData.reviewDate) }}
                </b-col>
                <b-col md="10" class="border-left">
                  <label class="font-weight-bolder">Respuesta:</label><br>
                  {{ agreementData.adminComments }}
                </b-col>
              </b-row>
            </div>
          </b-col>


          <b-col v-if="agreementsRelated.length" class="mb-2 mt-1">
            <app-collapse v-if="!isLoadingAgreementData" class="border-history">
							<app-collapse-item title="Historial de revisiones" :isVisible="false">
                <b-table
                  :items="agreementsRelated"
                  :fields="tableColumns"
                  responsive
                  small
                  sticky-header="350px"
                  show-empty
                  class="position-relative mb-0 "
                >
                  <template #cell(statusName)="row">
                    <b-badge pill :variant="badgeStatus(row.item)" v-b-popover.hover.top="row.item.comments" title="Comentarios del propietario">{{row.item.statusName}}</b-badge>
                  </template>
                  <template #cell(code)="row">
                    <b-link
                      v-if="can('fivesclub_web_owners_show_owner_transacctions_agreements_details_view')"
                      @click="goToRegister(row.item.id)"
                      class="font-weight-bolder link-text"
                    > {{ row.item.code }}
                    </b-link>
                    <span v-else>{{ row.item.code }}</span>
                  </template>
                  <template #cell(reviewStatusName)="row">
                    <div v-if="row.item.reviewStatus">
                      <b-badge pill :class="badgeReviewStatus(row. item)" v-b-popover.hover.top="row.item.adminComments" title="Comentarios de revisión" v-if="!!row.item.adminComments">{{row.item.reviewStatusName}}</b-badge>
                      <b-badge pill :class="badgeReviewStatus(row. item)" v-else>{{row.item.reviewStatusName}}</b-badge>
                    </div>
                    <span v-else>N/A</span>
                  </template>
                </b-table>
							</app-collapse-item>
						</app-collapse>
          </b-col>

          <b-col md="12" v-if="agreedTransactions">
            <div class="text-right">
              <b-button
                v-if="can('fivesclub_web_owners_show_owner_transacctions_agreements_detail_view_resolve_button')"
                :disabled="invalid || !notes || isSendingResponse"
                variant="primary"
                @click="sendResponse()"
              > <b-spinner small v-if="isSendingResponse" /> Resolver
              </b-button>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { showAlertMessage, formatDateOnly } from '@/helpers/helpers'
import * as moment from "moment"
import { acl } from "@/modules/auth/mixins/acl"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import router from '@/modules/auth/router';

export default {
  mixins: [acl],
  async created() {
    await this.init()
  },
  components: {    
    AppCollapseItem,
    AppCollapse,
  },
  data() {
    return {
      isLoadingAgreementData: false,
      isSendingResponse: false,
      authURL: process.env.VUE_APP_IMG_SRC_API,
      notes: null,
      idAgreement: this.$route.params.id,
      agreementData: null,
      agreementsRelated: [],
      tableColumns: [
        { key:'code', class:'text-center', tdClass: 'p-1', label: 'Id de la transacción', formatter: value => {
          return value || '--'
        }},
        { key:'ownerName', class:'text-center', tdClass: 'p-1', label: 'Propietario'},
        { key:'signDate', class:'text-center', tdClass: 'p-1', label: 'Fecha de Transacción'},

        { key:'statusName', class:'text-center', tdClass: 'p-1', label: 'Estado'},
        { key:'reviewStatusName', class:'text-center', tdClass: 'p-1', label: 'Resolución'},
        { key:'reviewByName', class:'text-center', tdClass: 'p-1', label: 'Revisado por'},
        { key:'reviewDate', class:'text-center', tdClass: 'p-1', label: 'Revisado el', formatter: value =>{
          return !!value ? this.formatThisDate(value) : ''
        }},
      ],
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    agreedTransactions(){
      return this.agreementData.status != 1 && !this.agreementData.adminComments
    },
   
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ["fetchOwnerTransactionsAcceptance", "fetchOwnerTransactionsAcceptanceRelated", "setOwnerTransactionsAcceptanceResponse"]),
    async init(){
      if(this.isANumber(this.idAgreement)){
        this.isLoadingAgreementData = true
        const selectedAgreement = await this.fetchOwnerTransactionsAcceptance({idAgreement: this.idAgreement})
        if (selectedAgreement) {
          this.agreementData = structuredClone(selectedAgreement)
          const relatedLogs = await this.fetchOwnerTransactionsAcceptanceRelated({
            idAgreement: this.idAgreement,
            periodId: selectedAgreement?.periodId,
            owner: selectedAgreement?.owner,
            contract: selectedAgreement?.contract,
            year: this.year(selectedAgreement?.periodInitDate)
          })
          this.agreementsRelated = relatedLogs
        }
        else {
          showAlertMessage( `No se han encontrado datos`, 'InfoIcon', 'Los detos no se han cargado correctamente, o es posible que el registro no exista. Por favor, inténtelo más tarde.', 'warning', 4000, 'bottom-right')
          this.$router.push({ name: 'owners-transactions-aceptance'})
        }
        this.isLoadingAgreementData = false
      }
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
    async sendResponse() {
      this.isSendingResponse = true
      this.agreementData.adminResponse = structuredClone(this.notes)
      this.agreementData.idUser = this.user.idUser
      const response = await this.setOwnerTransactionsAcceptanceResponse(this.agreementData)
      if (response) {
        showAlertMessage( `Respuesta enviada`, 'InfoIcon', 'Su respuesta ha sido enviada satisfactoriamente.', 'success', 4000, 'bottom-right')
        this.$router.push({ name: 'owners-transactions-aceptance', params: {reload: true}})
      }
      this.isSendingResponse = false
    },
    year(date){
      return moment(date || new Date()).format("YYYY")
    },
    formatThisDate(date){
      return formatDateOnly(date, 'es')
    },
    badgeStatus(request){
      const { status } = request

      let variant =  'bg-success'
      if(status === '1' ) variant = 'success'
      if( status === '2' ) variant = 'danger'

      return variant
    },
    async goToRegister(id){
      this.$router.push({ name: 'owners-transactions-aceptance-detail', params: { id } })
      this.idAgreement = this.$route.params.id,

      await this.init()
    },
    badgeReviewStatus(request){
      const { reviewStatus } = request

      let variant =  'bg-success'
      if(reviewStatus === '1' ) variant = 'btn-warning'
      if( reviewStatus === '2' ) variant = 'btn-outline-success'
      if(reviewStatus === '3' ) variant = 'btn-success'
      // if( reviewStatus === '4' ) variant = 'bg-danger'

      return variant
    }
  },
};
</script>
<style scoped>
.border-history{
  border: 1px solid #333366;
  margin-bottom: 2.5rem;
}
</style>

